import React from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import Navigation from "../../components/Navigation";

const SideBarContent = () => {
	const navigationMenus = [
		{
			name: 'custom.sidebar.main',
			type: 'section',
			children: [
				{
					name: 'custom.pages.dashboard',
					type: 'item',
					link: '/app/dashboard',
					icon: 'view-dashboard',
				},
				{
					name: 'custom.pages.users',
					type: 'item',
					link: '/app/users',
					icon: 'accounts',
					permissionName: 'users.index',
				}, 
				{
					name: 'custom.pages.categories',
					type: 'item',
					link: '/app/categories',
					icon: 'tab',
					permissionName: 'categories.index',
				},
				{
					name: 'custom.pages.brands',
					type: 'item',
					link: '/app/brands',
					icon: 'layers',
					permissionName: 'brands.index',
				}, 
				{
					name: 'custom.pages.products',
					type: 'item',
					link: '/app/products',
					icon: 'tag',
					permissionName: 'products.index',
				}, 
				{
					name: 'custom.pages.Promotions',
					type: 'item',
					link: '/app/Promotions',
					icon: 'tag',
					permissionName: 'Promotions.index',
				}, 
				{
					name: 'custom.pages.Bundles',
					type: 'item',
					link: '/app/Bundles',
					icon: 'tag',
					permissionName: 'Bundles.index',
				}, 
				{
					name: 'custom.pages.specification',
					type: 'item',
					link: '/app/Specifications',
					icon: 'tab',
					permissionName: 'Specifications.index',
				}, 
				{
					name: 'custom.pages.Slider',
					type: 'item',
					link: '/app/Slider',
					icon: 'tab',
					permissionName: 'Specifications.index',
				},   
				{
					name: 'custom.pages.category-banners',
					type: 'item',
					link: '/app/CategoryBanners',
					icon: 'tab',
					permissionName: 'CategoryBanners.index',
				},   
			],
		},
	]; 
	return (
		<CustomScrollbars className="scrollbar">
			<Navigation menuItems={navigationMenus}/>
		</CustomScrollbars>
	);
};

export default SideBarContent;
