import {
	FETCH_ERROR,
	FETCH_START,
	FETCH_SUCCESS,
	INIT_URL,
	SIGNOUT_USER_SUCCESS, TOKEN_EXPIRES_IN_SET,
	USER_DATA, USER_PERMISSIONS_SET,
	USER_TOKEN_SET,
	USER_TYPE_SET,
} from "../constants/ActionTypes";
import axios from '../util/Api';
import {switchLanguage} from "./Setting";
import languageData from "../components/LanguageSwitcher/data";

export const setInitUrl = (url) => {  
	return {
		type: INIT_URL,
		payload: url
	};
};

 
export const userSignIn = ({email, password, showErrorLoginNotification}) => {
	return (dispatch) => {
		dispatch({type: FETCH_START});
		axios.post('api/auth/login', {
				email: email,
				password: password,
			}
		).then(({data}) => {
			localStorage.setItem("token", data.token_type + " " + data.access_token);
			localStorage.setItem("expiresIn", new Date(new Date().getTime() + (data.expires_in * 1000)).getTime().toString());
			localStorage.setItem("userType", data.user_type);
			localStorage.setItem("language", data.language);
			setUserPermissions(dispatch, data.permissions);
			dispatch({type: FETCH_SUCCESS});
			dispatch({type: USER_TOKEN_SET, payload: data.token_type + " " + data.access_token});
			//dispatch({type: USER_TYPE_SET, payload: data.user_type});
			dispatch({type: TOKEN_EXPIRES_IN_SET, payload: localStorage.getItem('expiresIn')});
			if (data.language === 'ar') {
				dispatch(switchLanguage(languageData[1]));
			} else {
				dispatch(switchLanguage(languageData[0]));
			}
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR, payload: error.message});
			showErrorLoginNotification("custom.signIn.errorOnLogin");
		});
	}
};
export const forgetPassword = ({email, showErrorLoginNotification}) => {
	return (dispatch) => {
		if(email==''){
			showErrorLoginNotification("custom.forgetPassword.requiredEmail");
			return;
		}
		dispatch({type: FETCH_START}); 
		axios.post('api/auth/forget-password', {
				email: email, 
			}
		).then(({data}) => {
			dispatch({type: FETCH_SUCCESS});
			showErrorLoginNotification("Success");
		}).catch(function (error) { 
			dispatch({type: FETCH_ERROR, payload: error.message});
			showErrorLoginNotification("Error");
		});
	}
};

 
export const getUser = (token) => { 
	return (dispatch) => {
		dispatch({type: FETCH_START});
		axios.post('api/auth/me', null, {headers: {'Authorization': token}},
		).then(({data}) => {
			dispatch({type: FETCH_SUCCESS});
			dispatch({type: USER_DATA, payload: data});
		}).catch(function (error) {
			dispatch({type: FETCH_ERROR, payload: error.message});
		});
	}
};


export const userSignOut = (token) => { 
	return (dispatch) => {
		dispatch({type: FETCH_START});
		axios.post('https://highhawks.jo/api/auth/login', null, {headers: {'Authorization': token}},
		).then(({data}) => {

		}).catch(function (error) {
			dispatch({type: FETCH_ERROR, payload: error.message});
		}).finally(() => {
			localStorage.removeItem("token");
			localStorage.removeItem("expiresIn");
			localStorage.removeItem("userType");
			localStorage.removeItem("permissions");
			dispatch({type: FETCH_SUCCESS});
			dispatch({type: SIGNOUT_USER_SUCCESS});
		});
	}
};

export const setUserPermissions = (dispatch, permissions) => {
	if (permissions) {
		localStorage.setItem("permissions", JSON.stringify(permissions));
	}
	dispatch({type: USER_PERMISSIONS_SET, payload: permissions});
}

export const setUserPermissionsWithDispatch = (permissions) => {
	return (dispatch) => {
		if (permissions) {
			localStorage.setItem("permissions", JSON.stringify(permissions));
		}
		dispatch({type: USER_PERMISSIONS_SET, payload: permissions});
	}
}
