// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSED_NAV';
export const DRAWER_TYPE = 'DRAWER_TYPE';
export const FIXED_DRAWER = 'FIXED_DRAWER';
export const COLLAPSED_DRAWER = 'COLLAPSED_DRAWER';
export const MINI_DRAWER = 'MINI_DRAWER';
export const THEME_COLOR = 'THEME_COLOR';
export const DARK_THEME = 'DARK_THEME';
export const WINDOW_WIDTH = 'WINDOW_WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE';
export const CHANGE_DIRECTION = 'CHANGE_DIRECTION';

export const CHANGE_NAVIGATION_STYLE = 'CHANGE_NAVIGATION_STYLE';
export const HORIZONTAL_NAVIGATION = 'HORIZONTAL_NAVIGATION';
export const VERTICAL_NAVIGATION = 'VERTICAL_NAVIGATION';

export const HORIZONTAL_MENU_POSITION = 'HORIZONTAL_MENU_POSITION';
export const ABOVE_THE_HEADER = 'ABOVE_THE_HEADER';
export const INSIDE_THE_HEADER = 'INSIDE_THE_HEADER';
export const BELOW_THE_HEADER = 'BELOW_THE_HEADER';

//Contact Module const
export const FETCH_START = 'FETCH_START';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_ERROR = 'FETCH_ERROR';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';

//Auth const
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const USER_DATA = 'USER_DATA';
export const USER_TOKEN_SET = 'USER_TOKEN_SET';
export const USER_PERMISSIONS_SET = 'USER_PERMISSIONS_SET';
export const USER_TYPE_SET = 'USER_TYPE_SET';
export const TOKEN_EXPIRES_IN_SET = 'TOKEN_EXPIRES_IN_SET';

// Page
export const CRUD_PAGE_SET_SEARCH_VALUE = 'CRUD_PAGE_SET_SEARCH_VALUE';
export const CRUD_PAGE_SET_ROWS_PER_PAGE = 'CRUD_PAGE_SET_ROWS_PER_PAGE';
export const CRUD_PAGE_SET_RESULTS = 'CRUD_PAGE_SET_RESULTS';
export const PRICE_LIST_SET_RESULTS='PRICE_LIST_SET_RESULTS';
export const CRUD_PAGE_PRODUCT_RESULTS='CRUD_PAGE_PRODUCT_RESULTS';
export const CRUD_PAGE_SET_TOTAL_COUNT = 'CRUD_PAGE_SET_TOTAL_COUNT';
export const MAIN_NAME='MAIN_NAME';
export const CRUD_PAGE_TABLE_METADATE_RESULTS='CRUD_PAGE_TABLE_METADATE_RESULTS';
export const CRUD_PAGE_SET_RELATED_SPEC_RESULTS='CRUD_PAGE_SET_RELATED_SPEC_RESULTS'; 
export const CRUD_PAGE_SET_ALL_SPEC_RESULTS='CRUD_PAGE_SET_ALL_SPEC_RESULTS'; 
export const CRUD_PAGE_SET_LOADING = 'CRUD_PAGE_SET_LOADING';
export const CRUD_PAGE_SET_PAGE_NUMBER = 'CRUD_PAGE_SET_PAGE_NUMBER';
export const CRUD_PAGE_RESET = 'CRUD_PAGE_RESET';
export const  CRUD_PAGE_SET_AlERT_VISIBLE ='CRUD_PAGE_SET_AlERT_VISIBLE';
export const  CRUD_PAGE_SET_AlERT_MESSAGE ='CRUD_PAGE_SET_AlERT_MESSAGE';
export const CRUD_PAGE_SET_LONG_DESC_RESULT='CRUD_PAGE_SET_LONG_DESC_RESULT';
