import React from "react";
import IntlMessages from "./IntlMessages";

//export const baseURL = 'http://140.82.35.237:9000/';
export const baseURL='https://highhawks.jo/';
export const getRowsPerPageOptions = () => {
	return [5,10];
}

export const getRowsPerPageDefaultValue = () => {
	return 5;
}

export const getImagePath = (path) => { 
	const temp = baseURL + "storage/brands/";
	return path.replace("public", temp);		
}

const statusOptions = [
	{
		label: <IntlMessages id="custom.general.active"/>,
		value: 1
	},
	{
		label: <IntlMessages id="custom.general.inactive"/>,
		value: 0
	},
];

export const getStatusOptions = () => {
	return statusOptions;
}

export const isEmail = (email) => {
	return (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email));
}
