import React, {useEffect, useState} from 'react';
import {Link, Redirect} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages'; 
import CircularProgress from "@material-ui/core/CircularProgress";
import {NotificationContainer, NotificationManager} from "react-notifications";
import classes from './SignIn.module.css';
import axios from '../util/Api';
import { red } from '@material-ui/core/colors';

const ResetPassword = (props) => {
 	//const query = new URLSearchParams(props.location.Token);  
    const Token=props.match.params.Token;
	console.log(props.match.params.Token);   
	const [email, setEmail] = useState(''); 
    const [emailError, setEmailError] = useState(''); 
    const [password, setPassword] = useState(''); 
	const [passwordConfirm, setPasswordConfirm] = useState(''); 
    const [PasswordError, setPasswordError] = useState('');  
	const [passwordConfirmError, setPasswordConfirmError] = useState(''); 
	const dispatch = useDispatch(); 
	const {loading} = useSelector(({common}) => common);

 

	const showErrorLoginNotification = (message) => {
		NotificationManager.error(<IntlMessages id={message}/>);
	} 
    const ResetPass=()=>{ 
		setEmailError('');
		setPasswordError('');
		setPasswordConfirmError('');
		let isValid = true;   
		if (email=='') {
			setEmailError('this field is required');
			isValid = false;
			} 
		if (password=='' || password.length < 6) {
		setPasswordError('Password must be more than 6 charactor');
		isValid = false;
		} 
		if(passwordConfirm=='') {
			setPasswordConfirmError('this field is required');
			isValid = false;
	    } 
		else if (password !== passwordConfirm) {
				setPasswordConfirmError('passwords do not match');
				isValid = false;
		}  
		if(isValid){
			axios.post('api/auth/reset-password/'+Token, {
				email: email,
				password: password,
				password_confirmation:passwordConfirm
			}
		).then(({data}) => {
			showErrorLoginNotification("custom.forgetPassword.message");
		}).catch(function (error) {  
			showErrorLoginNotification("custom.forgetPassword.errorOnforgetPassword");
		});
		}
			
	 
	}
	 
	return (
		<div
			className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
			<div className="app-login-main-content">

				<div className="app-logo-content d-flex align-items-center justify-content-center">
					<Link className={classes.SideSection} to="/" title="Mtajer">
						<IntlMessages id="custom.general.productName"/>
					</Link>
				</div>

				<div className="app-login-content">
					<div className="app-login-header mb-4">
						<h1><IntlMessages id="custom.forgetPassword"/></h1>
					</div>

					<div className="app-login-form">
						<form>
							<fieldset>
								<TextField
									label={<IntlMessages id="custom.signIn.email"/>}
									fullWidth
									onChange={(event) => setEmail(event.target.value)}
									defaultValue={email}
									margin="normal"
									className="mt-1 my-sm-3"
								/>
							<span style={{color:'red'}}>{emailError}</span> 
							 <TextField
									label={<IntlMessages id="custom.signIn.password"/>}
									fullWidth
									onChange={(event) => setPassword(event.target.value)}
									defaultValue={password}
									margin="normal"
									className="mt-1 my-sm-3"
									type="password"
								/>
							
								<span style={{color:'red'}}>	{PasswordError}</span> 
                                <TextField
									label={<IntlMessages id="custom.signIn.Confirmpassword"/>}
									fullWidth
									onChange={(event) => setPasswordConfirm(event.target.value)}
									defaultValue={passwordConfirm}
									margin="normal"
									className="mt-1 my-sm-3"
									type="password"
								/> 
								<span style={{color:'red'}}>	  {passwordConfirmError}</span> 
								<div className="mb-3 d-flex align-items-center justify-content-between">
									<Button onClick={ResetPass} variant="contained" color="primary">
										Submit
									</Button>
								</div>
						 
							</fieldset>
						</form>
					</div>
				</div>
			</div>

			{
				loading &&
				<div className="loader-view">
					<CircularProgress/>
				</div>
			}

			<NotificationContainer/>
		</div>
	);
};


export default ResetPassword;
