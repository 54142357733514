import React, {useState} from "react";
import {Link, withRouter} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import {Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";
import {COLLAPSED_DRAWER, FIXED_DRAWER} from "constants/ActionTypes";
import {switchLanguage, toggleCollapsedNav} from "actions/Setting";
import LanguageSwitcher from "components/LanguageSwitcher/index";
import classes from "./Header.module.css";
import IntlMessages from "../../../../util/IntlMessages";

const Index = (props) => {

	const dispatch = useDispatch();
	const {drawerType, locale, navCollapsed} = useSelector(({settings}) => settings);
	const [langSwitcher, setLangSwitcher] = useState(false);

	const onLangSwitcherSelect = (event) => {
		setLangSwitcher(!langSwitcher);
	};

	const handleRequestClose = () => {
		setLangSwitcher(false);
	};

	const onToggleCollapsedNav = (e) => {
		const val = !navCollapsed;
		dispatch(toggleCollapsedNav(val));
	};

	const onSwitchLanguage = (lang) => {
		dispatch(switchLanguage(lang))
	};

	const drawerStyle = drawerType.includes(FIXED_DRAWER) ? "d-block d-xl-none" : drawerType.includes(COLLAPSED_DRAWER) ? "d-block" : "d-none";

	return (
		<AppBar className="app-main-header">
			<Toolbar className="app-toolbar" disableGutters={false}>

				<IconButton className={`jr-menu-icon mr-3 ${drawerStyle}`} aria-label="Menu"
							onClick={onToggleCollapsedNav}>
					<span className="menu-icon"/>
				</IconButton>

				<Link className={classes.NameLink} to="/app/dashboard">
					<IntlMessages id="custom.general.productName"/>
				</Link>

				<div className="ellipse-shape"/>
			</Toolbar>
		</AppBar>
	);
};


export default withRouter(Index);
