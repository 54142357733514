import React from "react";

export const isSuperAdmin = (userType) => {
	if (userType) {
		if (userType === 'super_admin') {
			return true;
		} else {
			return false;
		}
	} else {
		return false;
	}
}

export const hasPermission = (permissions, permissionName) => {
	if (permissions[permissionName]) {
		return true;
	} else {
		return false;
	}
}

export const isExpiredToken = () => {
	if (new Date().getTime() >= new Date(Number(localStorage.getItem("expiresIn"))).getTime()) {
		return true;
	} else {
		return false;
	}
}
