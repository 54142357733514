import {
	INIT_URL,
	SIGNOUT_USER_SUCCESS,
	USER_DATA,
	USER_TOKEN_SET,
	USER_PERMISSIONS_SET,
	USER_TYPE_SET,
	TOKEN_EXPIRES_IN_SET,
} from "../constants/ActionTypes";

const INIT_STATE = {
	token: localStorage.getItem('token'), 
	expiresIn: localStorage.getItem('expiresIn'),
	initURL: '', 
	isUserDataFetched: false,
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

		case INIT_URL: {
			return {...state, initURL: action.payload};
		}

		case SIGNOUT_USER_SUCCESS: {
			return {
				...state,
				token: null,
				expiresIn: null,
				initURL: '',
				permissions: {},
				authUser: {},
				isUserDataFetched: false,
			}
		}

		case USER_DATA: {
			return {
				...state,
				authUser: {
					...state.authUser,
					firstName: action.payload.name,
					lastName: '',
				},
				isUserDataFetched: true,
			};
		}

		case USER_TOKEN_SET: {
			return {
				...state,
				token: action.payload,
			};
		}

		case TOKEN_EXPIRES_IN_SET: {
			return {
				...state,
				expiresIn: action.payload,
			};
		}

		case USER_PERMISSIONS_SET: {
			return {
				...state,
				permissions: action.payload ? action.payload : {},
			};
		}

		case USER_TYPE_SET: {
			return {
				...state,
				userType: action.payload,
			};
		}

		default:
			return state;
	}
}
