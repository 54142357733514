import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar'
import {useDispatch, useSelector} from 'react-redux'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {userSignOut} from 'actions/Auth';
import IntlMessages from 'util/IntlMessages';
import {withRouter} from "react-router";
import {CircularProgress} from "@material-ui/core";

const UserInfo = (props) => {

	const dispatch = useDispatch();
	const token = useSelector(({auth}) => auth.token);
	const isUserDataFetched = useSelector(({auth}) => auth.isUserDataFetched);
	const authUser = useSelector(({auth}) => auth.authUser);

	const [anchorE1, setAnchorE1] = useState(null);
	const [open, setOpen] = useState(false);

	const handleClick = event => {
		setOpen(true);
		setAnchorE1(event.currentTarget);
	};

	const handleRequestClose = () => {
		setOpen(false);
	};

	const openProfilePage = () => {
        handleRequestClose();
		props.history.push('/app/profile');
	}

	return (
		<div className="user-profile d-flex flex-row align-items-center">
			<Avatar
				alt='userAvatar'
				className="user-avatar"
			>
				{isUserDataFetched ? authUser.firstName.charAt(0) + authUser.lastName.charAt(0) : <CircularProgress/>}
			</Avatar>
			<div className="user-detail" style={{maxWidth: '150px'}}>
				<h4 className="user-name d-flex" onClick={handleClick}><span
					className='text-truncate'>{isUserDataFetched ? authUser.firstName + ' ' + authUser.lastName : "....................."}</span> <i
					className="zmdi zmdi-caret-down zmdi-hc-fw align-middle"/>
				</h4>
			</div>
			<Menu className="user-info"
				  id="simple-menu"
				  anchorEl={anchorE1}
				  open={open}
				  onClose={handleRequestClose}
				  PaperProps={{
					  style: {
						  minWidth: 120,
						  paddingTop: 0,
						  paddingBottom: 0
					  }
				  }}
			>
				<MenuItem onClick={openProfilePage}>
					<i className="zmdi zmdi-account zmdi-hc-fw mr-2"/>
					<IntlMessages id="custom.sidebar.profile"/>
				</MenuItem>
				<MenuItem onClick={() => {
					handleRequestClose();
					dispatch(userSignOut(token));
				}}>
					<i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2"/>

					<IntlMessages id="custom.sidebar.logout"/>
				</MenuItem>
			</Menu>
		</div>
	);
};

export default withRouter(UserInfo);


