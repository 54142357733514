import React from 'react';
import {List} from '@material-ui/core';
import {NavLink} from 'react-router-dom';

import IntlMessages from "../../util/IntlMessages";
import {useSelector} from "react-redux";
import {hasPermission, isSuperAdmin} from "../../util/Auth";

const NavMenuItem = props => {
	const {name, icon, permissionName, link} = props;
	//const userType = useSelector(({auth}) => auth.userType);
	//const permissions = useSelector(({auth}) => auth.permissions); 
	let	hideClass = '';
	//if (permissionName === undefined || permissionName === null) {
	//	hideClass = '';
	//} else if (isSuperAdmin(userType) || hasPermission(permissions, permissionName)) {
	//	hideClass = '';
	//}  
	return (
		<List component="div" className={'nav-menu-item ' + hideClass}>
			<NavLink className="prepend-icon nav-menu-link" to={link}>
				{/* Display an icon if any */}
				{!!icon && (
					<i className={'zmdi zmdi-hc-fw  zmdi-' + icon}/>
				)}
				<span className="nav-text"><IntlMessages id={name}/></span>
			</NavLink>
		</List>
	)
};

export default NavMenuItem;
