import {
	CRUD_PAGE_RESET,
	CRUD_PAGE_SET_LOADING,
	CRUD_PAGE_SET_PAGE_NUMBER,
	CRUD_PAGE_SET_RESULTS,
	CRUD_PAGE_SET_ROWS_PER_PAGE,
	CRUD_PAGE_SET_SEARCH_VALUE,
	CRUD_PAGE_SET_RELATED_SPEC_RESULTS, 
	CRUD_PAGE_SET_TOTAL_COUNT,
	PRICE_LIST_SET_RESULTS,
	MAIN_NAME,
	CRUD_PAGE_PRODUCT_RESULTS,
	CRUD_PAGE_TABLE_METADATE_RESULTS,
	CRUD_PAGE_SET_ALL_SPEC_RESULTS,
	CRUD_PAGE_SET_AlERT_VISIBLE, 
	CRUD_PAGE_SET_AlERT_MESSAGE,
	CRUD_PAGE_SET_LONG_DESC_RESULT
} from "../constants/ActionTypes";
import {getRowsPerPageDefaultValue} from "../util/General";

const INIT_STATE = {
	searchValue: '',
	rowsPerPage: getRowsPerPageDefaultValue(),
	results: [],
	tableMeta:[],
	priceList:[],
	allSpecs:[],
	relatedSpec:[],
	MainName:'',
	totalCount: 0,
	pageNumber: 0,
	isLoading: false,
	isAlertVisible:false,
	alertMessage:'',
	ProductList:[],
	longDesc:''
};

export default (state = INIT_STATE, action) => {
	switch (action.type) { 
		case CRUD_PAGE_SET_SEARCH_VALUE: {
			return {
				...state,
				searchValue: action.payload,
			}
		}
		case CRUD_PAGE_SET_AlERT_MESSAGE: {
			return {
				...state,
				alertMessage: action.payload,
			}
		}
		case CRUD_PAGE_SET_ROWS_PER_PAGE: {
			return {
				...state,
				rowsPerPage: action.payload,
			}
		}
		case CRUD_PAGE_SET_ALL_SPEC_RESULTS: {
			return {
				...state,
				allSpecs: action.payload,
			}
		}
		case CRUD_PAGE_SET_AlERT_VISIBLE:{
			return {
				...state,
				isAlertVisible: action.payload,
			}
		}
		case CRUD_PAGE_SET_RELATED_SPEC_RESULTS: {
			return {
				...state,
				relatedSpec: action.payload,
			}
		}
		case CRUD_PAGE_SET_RESULTS: {
			return {
				...state,
				results: action.payload,
			}
		}
		case CRUD_PAGE_TABLE_METADATE_RESULTS: {
			return {
				...state,
				tableMeta: action.payload,
			}
		}
		case PRICE_LIST_SET_RESULTS:{
			return{
				...state,
				priceList:action.payload,
			}
		}
		case CRUD_PAGE_PRODUCT_RESULTS:{
			return{
				...state,
				ProductList:action.payload,
			}
		}
        case MAIN_NAME:{
           return{
			   ...state,
			   MainName:action.payload,
		   }
        }
		case CRUD_PAGE_SET_TOTAL_COUNT: {
			return {
				...state,
				totalCount: action.payload,
			}
		}

		case CRUD_PAGE_SET_PAGE_NUMBER: {
			return {
				...state,
				pageNumber: action.payload,
			}
		}

		case CRUD_PAGE_SET_LOADING: {
			return {
				...state,
				isLoading: action.payload,
			}
		}
         case CRUD_PAGE_SET_LONG_DESC_RESULT:{
				return {
					...state,
					longDesc: action.payload,
		}
}
		case CRUD_PAGE_RESET: {
			return {
				...state,
				searchValue: '',
				results: [],
				priceList:[],
				MainName:'',
				totalCount: 0,
				pageNumber: 0,
				isLoading: false,
			}
		}

		default:
			return state;

	}
}
