import axios from 'axios';
import {baseURL} from "./General";
import {setUserPermissionsWithDispatch} from "../actions";

const axiosInstance = axios.create({
	baseURL: baseURL,
});

axiosInstance.interceptors.response.use(
	(response) => {
		let userType = response.data.userType;
		if (userType === 'admin') {
			let newPermissions = response.data.permissions;
			setUserPermissionsWithDispatch(newPermissions);
		}
		return response;
	}
);

export default axiosInstance;
