import {
	CHANGE_DIRECTION,
	CHANGE_NAVIGATION_STYLE,
	DARK_THEME,
	DRAWER_TYPE,
	FIXED_DRAWER,
	HORIZONTAL_MENU_POSITION,
	INSIDE_THE_HEADER,
	SWITCH_LANGUAGE,
	THEME_COLOR,
	VERTICAL_NAVIGATION,
	WINDOW_WIDTH
} from 'constants/ActionTypes';
import {DARK_INDIGO} from 'constants/ThemeColors';
import languageData from "../components/LanguageSwitcher/data";

const rltLocale = ['ar'];
const initialSettings = {
	drawerType: FIXED_DRAWER,
	themeColor: DARK_INDIGO,
	darkTheme: false,
	width: window.innerWidth,
	isDirectionRTL: localStorage.getItem("language") === 'ar',
	navigationStyle: VERTICAL_NAVIGATION,
	horizontalNavPosition: INSIDE_THE_HEADER,
	locale: localStorage.getItem("language") === 'ar' ? languageData[1] : languageData[0],
};

const settings = (state = initialSettings, action) => {
	switch (action.type) {
		case DRAWER_TYPE:
			return {
				...state,
				drawerType: action.drawerType
			};
		case WINDOW_WIDTH:
			return {
				...state,
				width: action.width
			};
		case THEME_COLOR:
			return {
				...state,
				darkTheme: false,
				themeColor: action.color
			};
		case DARK_THEME:
			return {
				...state,
				darkTheme: !state.darkTheme
			};
		case SWITCH_LANGUAGE:

			return {
				...state,
				locale: action.payload,
				isDirectionRTL: rltLocale.includes(action.payload.locale)

			};
		case CHANGE_DIRECTION:
			return {
				...state,
				isDirectionRTL: !state.isDirectionRTL

			};

		case CHANGE_NAVIGATION_STYLE:
			return {
				...state,
				navigationStyle: action.payload
			};


		case HORIZONTAL_MENU_POSITION:
			return {
				...state,
				horizontalNavPosition: action.payload
			};


		default:
			return state;
	}
};

export default settings;
