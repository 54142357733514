import React, {useEffect, useState} from 'react';
import {Link, Redirect} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import {forgetPassword} from 'actions/Auth';
import CircularProgress from "@material-ui/core/CircularProgress";
import {NotificationContainer, NotificationManager} from "react-notifications";
import classes from './SignIn.module.css';

const SignIn = (props) => {
	const query = new URLSearchParams(props.location.search);
	const emailParam =  query.get("email");
	const [email, setEmail] = useState(emailParam!=undefined?emailParam:''); 
	const [redirect, setRedirect] = useState(false);
	const dispatch = useDispatch();
	const token = useSelector(({auth}) => auth.token);
	const {loading} = useSelector(({common}) => common);

	useEffect(() => { 
		if (token !== null) {
			props.history.push('/');
		}
	}, [token]);

	const showErrorLoginNotification = (message) => {
		if("Success"){ 
			NotificationManager.success(<IntlMessages id="custom.forgetPassword.message"/>);
		}
		else
		    NotificationManager.error(<IntlMessages id="custom.forgetPassword.errorOnforgetPassword"/>);
	} 
	return (
		<div
			className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
			<div className="app-login-main-content">

				<div className="app-logo-content d-flex align-items-center justify-content-center">
					<Link className={classes.SideSection} to="/" title="Mtajer">
						<IntlMessages id="custom.general.productName"/>
					</Link>
				</div>

				<div className="app-login-content">
					<div className="app-login-header mb-4">
						<h1><IntlMessages id="custom.forgetPassword"/></h1>
					</div>

					<div className="app-login-form">
						<form>
							<fieldset>
								<TextField
									label={<IntlMessages id="custom.signIn.email"/>}
									fullWidth
									onChange={(event) => setEmail(event.target.value)}
									defaultValue={email}
									margin="normal"
									className="mt-1 my-sm-3"
								/>
							 

								<div className="mb-3 d-flex align-items-center justify-content-between">
									<Button onClick={() => {
										dispatch(forgetPassword({email, showErrorLoginNotification}));
									}} variant="contained" color="primary">
										Submit
									</Button>
								</div>
						 
							</fieldset>
						</form>
					</div>
				</div>
			</div>

			{
				loading &&
				<div className="loader-view">
					<CircularProgress/>
				</div>
			}

			<NotificationContainer/>
		</div>
	);
};


export default SignIn;
