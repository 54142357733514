import React from 'react';
import {Link} from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';

const Error404 = () => (
	<div className="page-error-container" style={{width: '100%'}}>
		<div className="page-error-content">
			<div className="error-code mb-4">401</div>
			<h2 className="text-center fw-regular title">
				<IntlMessages id="custom.401.message"/>
			</h2>
			<p className="text-center">
				<Link className="btn btn-primary" to={{pathname: "/app/dashboard"}}><IntlMessages
					id="custom.401.button"/></Link>
			</p>
		</div>
	</div>
);

export default Error404;
