import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import asyncComponent from '../../util/asyncComponent';
import {useDispatch, useSelector} from "react-redux";
import {userSignOut} from "../../actions";
import {isExpiredToken} from "../../util/Auth";

const Routes = ({match}) => {  
    const dispatch = useDispatch();

    const token = useSelector(({auth}) => auth.token);
    const userType = useSelector(({auth}) => auth.userType);
    //const permissions = useSelector(({auth}) => auth.permissions);

    if (isExpiredToken()) {
        dispatch(userSignOut(token));
        return (
            <div/>
        );
    } else {
        return ( 
            <Switch> 
                <Route path={`${match.url}/dashboard`} component={asyncComponent(() => import('./Dashboard'))}/>
                <Route path={`${match.url}/profile`} component={asyncComponent(() => import('./Profile'))}/>
                <Route path={`${match.url}/users`} component={asyncComponent(() => import('./Users'), userType, "users.index")}/>
                <Route path={`${match.url}/brands`} component={asyncComponent(() => import('./Brands'), userType, "brands.index")}/>
                <Route path={`${match.url}/shippers`} component={asyncComponent(() => import('./Shippers'), userType, "shippers.index")}/>
                <Route path={`${match.url}/categories`} component={asyncComponent(() => import('./Categories'), userType, "categories.index")}/>
                <Route path={`${match.url}/SubCategories/:id`} component={asyncComponent(() => import('./SubCategories'), userType, "categories.index")}/>
                <Route path={`${match.url}/stores`} component={asyncComponent(() => import('./Stores'), userType, "stores.index")}/>
                <Route path={`${match.url}/Products`} component={asyncComponent(() => import('./Products'), userType, "Products.index")}/>
                <Route path={`${match.url}/ProductFiles/:id/:name`} component={asyncComponent(() => import('./ProductFiles'), userType, "ProductFiles.index")}/>
                <Route path={`${match.url}/branches`} component={asyncComponent(() => import('./Branches'), userType, "branches.index")}/>
                <Route path={`${match.url}/Promotions`} component={asyncComponent(() => import('./Promotions'), userType, "Promotions.index")}/>
                <Route path={`${match.url}/Bundles`} component={asyncComponent(() => import('./Bundles'), userType, "Bundles.index")}/>
                <Route path={`${match.url}/BundleInfo/:id/:name`} component={asyncComponent(() => import('./BundleInfo'), userType, "BundleInfo.index")}/>        
                <Route path={`${match.url}/Specifications`} component={asyncComponent(() => import('./Specifications'), userType, "Specifications.index")}/>
                <Route path={`${match.url}/SpecificationsInfo/:id/:name`} component={asyncComponent(() => import('./SpecificationsInfo'), userType, "SpecificationsInfo.index")}/>
                <Route path={`${match.url}/Slider`} component={asyncComponent(() => import('./Slider'), userType, "Slider.index")}/>
                <Route path={`${match.url}/CategoryBanners`} component={asyncComponent(() => import('./CategoryBanners'), userType, "CategoryBanners.index")}/>
                         
                <Route component={asyncComponent(() => import("./404"))}/>
            </Switch>
        );
    }

}

export default withRouter(Routes);
