import React, {useEffect} from "react";
import AppLayouts from "./AppLayouts";
import Routes from "../../app/routes";
import {useDispatch, useSelector} from "react-redux";
import {getUser} from 'actions/Auth';

const AppLayout = () => {

    const dispatch = useDispatch();

	const horizontalNavPosition = useSelector(({settings}) => settings.horizontalNavPosition);
	const navigationStyle = useSelector(({settings}) => settings.navigationStyle);
	const onGetLayout = (layout) => {
		switch (layout) {
			case "inside_the_header":
				return "InsideHeaderNav";

			case "above_the_header":
				return "AboveHeaderNav";

			case "below_the_header":
				return "BelowHeaderNav";
			default:
				return "Vertical";
		}
	};

	const isUserDataFetched = useSelector(({auth}) => auth.isUserDataFetched);
	const token = useSelector(({auth}) => auth.token);

	useEffect(() => {
		if (!isUserDataFetched && token) {
			dispatch(getUser(token));
		}
	}, [isUserDataFetched, token]);


	const Layout = AppLayouts[navigationStyle === "vertical_navigation" ? "Vertical" : onGetLayout(horizontalNavPosition)];

	return (
		<Layout>
			<Routes/>
		</Layout>
	);
};

export default AppLayout;
